.shop-category {
   margin: 1.5rem;
}

.shopcategory-products {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   margin-top: 0.7rem;
   gap: 1rem;
}

.shopCategory_banner {
   width: 100%;
   padding: 0.6rem;
}