.header {
    width: 100%;
    position: absolute;
    /* top: 0; */
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
}

.nav {
    height:  calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5rem;
}

.nav__logo, .nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
    font-size: 1rem;
}

.nav__list {
    display: flex;
    column-gap: 2rem;
    margin-bottom: 0;
}

.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    transition: .3s;
}

.nav__icon,
 .nav__close,
  .nav__toggle {
    display: none;
}


/* Active link */
.active-link,
.nav__link:hover {
    color: var(--title-color-dark);
}

/* Change background header */
.scroll-header {
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    /* background-color: rgb(234, 230, 230); */
    background-color: white;
}

.NavMain__Container {
    height: 3rem;
}

.navText__style {
    color: rgb(73, 72, 72); 
}
/*======================================== BREAKPOINTS ========================================*/
@media screen and (max-width: 1200px) {
    .NavMain__Container {
        height: 4rem;
    }
}

/* for large devices */
@media screen and (max-width: 992px) {
    .nav__list {
        column-gap: 1.2rem;
    }
}


/* for medium devices */
@media screen and (max-width: 768px) {
    .navText__style {
        color: white; 
    }

    .NavMain__Container {
        height: 0;
    }

    .header {
        top: initial;
        position: fixed;
        bottom: 0;
        background-color: white;
    }

    .nav {
        height: var(--header-height);
        column-gap: 55%;
    }

    .nav__menu {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: grey;
        color: white;
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }

    /* SHOW MENU */
    .show-menu {
        bottom: 0;
    }

    /* .nav__logo {
        color: white;
    } */

    .nav__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }

    .nav__icon {
        font-size: 1.2rem;
    }

    .nav__close {
        position: absolute;
        right: 1.3rem;
        bottom: 0.3rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--title-color);
    }

    .nav__close:hover {
        color: var(--title-color-dark);
    }

    .nav__toggle {
        font-size: 1.1rem;
        cursor: pointer;
    }

    .nav__icon, .nav__close, .nav__toggle {
        display: block;
    }
}

/* for small devices */
@media screen and (max-width: 350px) {
    .nav__menu {
        padding: 2rem 0.25rem 4rem;
    }

    .nav__list {
        column-gap: 0;
    }
}






