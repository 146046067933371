@import url('https://fonts.googleapis.com/css2?family=Teko&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@600&display=swap');
 /*========================= VARIABLES CSS =========================*/
:root {
  --header-height: 3rem;

  /*================ Colors ===================*/
  /* Colors mode HLS(hue, saturation, lightness) */
  --hue: 0;
  --satL: 0%;
  --color-grey: rgba(128, 128, 128, 0.975);
  --color-light-grey: rgba(128, 128, 128, 0.086);
  --title-color: hsl(var(--hue), var(--sat), 20%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-Color: hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;

  /*================ Font and typography ==============*/
  --body-font: "Poppins", sans-serif;

  /* .5rem = 8px | 1rem = 16px */
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /*========================== Font weight =========================*/
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;


  /*========================= Margin Bottom =========================*/
  /* .5rem = 8px | 1rem = 16px */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========================= Z index ===============================*/
  --z-tooltip: 10;
  --z-fixed: 100; 
  --z-modal: 1000;
}


 /*=============================== Base ==================================*/
 *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

/*================================= REUSABLE CSS CLASSES ===================================*/


/*========================================= LAYOUT ============================================*/
.container {
/* max-width: 968px;
margin-left: auto;
margin-right: auto; */
display: flex;
justify-content: center;
margin: 0 auto;
align-items: center;
}

.containerr {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: grid;
  gap: 1.5rem;
}



/*======================================== BREAKPOINTS ========================================*/
/* for large devices */
@media screen and (max-width: 992px) {
/* .container {
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
} */
}

/* for medium devices */
@media screen and (max-width: 768px) {
body {
  margin: 0 0 var(--header-height) 0;
}

.section {
  padding: 2rem 0 4rem;
}

.section__subtitles {
  margin-bottom: var(--mb-3);
}
}

@media screen and (max-width: 576px) {
}

/* for small devices */
@media screen and (max-width: 350px) {

:root {
  --big-font-size: 2.25rem;
}


.container {
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}
}



/*======================================== BREAKPOINTS ========================================*/
/* for large devices */
@media screen and (max-width: 992px) {
}

/* for medium devices */
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
}

/* for small devices */
@media screen and (max-width: 350px) {
}

