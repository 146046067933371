footer {
  text-align: center;
  padding: 5rem;
  margin-top: 3rem;
  background-color: rgba(164, 164, 164, 0.414);
  color: grey;
}

.footer__logo {
  color: black;
}

.Foter__item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.7rem;
}

.footer__social {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    
}

.footer__social a {
  color: black;
}

