.about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 2rem;
    /* padding: 1rem; */
    margin-bottom: 5rem;
}

.about__img {
    /* width: 350px; */
    width: 500px;
    /* width: 100%; */
    border-radius: 1.5rem;
    justify-self: center;
}

.about__info {
    grid-template-columns: repeat(3, 140px);
    gap: 0.5rem;
    margin-bottom: var(--mb-2);
}

.about__box {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
}

.about__icon {
    font-size: 1.5rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-5);
} 

.about__title {
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
}

.about__subtitle {
    font-size: var(--tiny-font-size);
    
}

.about__description {
    padding: 0 4rem 0 0;
    margin-bottom: var(--mb-2-5);
    color: rgb(73, 72, 72); 
}

.section__title-introduction {
    margin-bottom: 1rem;
}

.section__title {
    display: flex;
    justify-content: center;
}

.margin__top {
    margin-top: 2rem;
}

.aboutText__color {
    color: black;
}

/*======================================== BREAKPOINTS ========================================*/
/* for large devices */
@media screen and (max-width: 992px) {
   .about__container {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
    margin-bottom: 0;
   }

   .about__img {
    width: 350px;
   }

   .about__box {
    padding: 0.75rem 0.5rem;
   }

   .about__data {
    text-align: center;
   }

   .about__info {
    justify-content: center;
   }

   .about__description {
    padding: 0 5rem;
    margin-bottom: 2rem;
   }
}

/* for medium devices */
@media screen and (max-width: 768px) {
    /* .about__img {
        display: none;
    } */

    .margin__top {
        margin: 0;
    }

    .about__img {
        width: 80%;
    }
  }
  
  @media screen and (max-width: 576px) {

    .about__container {
        padding: 0 1rem;
        
    }

    .about__info {
        grid-template-columns: repeat(3, 1fr);
    }

    .about__description {
        padding: 0;
    }

    .about__img {
        width: 100%;
    }
  }
  
  /* for small devices */
  @media screen and (max-width: 350px) {
    .about__info {
        grid-template-columns: repeat(2, 1fr);
    }
  }