.item {
    width: 220px;
    height: 370px;
    background: white;
    padding: 0.7rem;
    border-radius: 0.6rem;
    transition: 0.4s ease-out;
    text-align: center;
}

.item-image {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 5px;
}

.item p {
    margin: 6px 0px;
}

.item-prices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0;
}

.item-price-new {
    color: orangered;
    font-size: 18px;
    font-weight: 600;
}

.item-price-old {
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}

.item:hover {
    transform: translateY(-10px);
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
}

/*======================================== BREAKPOINTS ========================================*/
/* for large devices */
@media screen and (max-width: 992px) {
}

/* for medium devices */
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
    .item {
        width: 180px;
    }
}

@media screen and (max-width: 400px) {
    .item {
        width: 160px;
    }
}

/* for small devices */
@media screen and (max-width: 350px) {
    .item {
        width: 260px;
    }
}

