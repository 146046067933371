.scrollup {
    position: fixed;
    right: 2.5rem;
    bottom: -20%;
    /* background-color: rgba(164, 164, 164, 0.414); */
    background-color: black;
    opacity: 0.8;
    padding: 0.3rem 0.5rem;
    border-radius: 0.4rem;
    z-index: var(--z-tooltip);
    transition: .4s;
}

.scrollup__icon {
    font-size: 2.5rem;
    color: var(--container-color);
}

.scrollup:hover {
    background-color: black;
}

/* Show Scroll */
.show-scroll {
    bottom: 3rem;
}

.cansel-scroll {
    display: none;
}

/*======================================== BREAKPOINTS ========================================*/
/* for large devices */
@media screen and (max-width: 992px) {
    .scrollup {
        right: 1.5rem;
        padding: 0.25rem 0.4rem;
    }

    .scroll__icon {
        font-size: 1.25rem;
    }
  }
  
  /* for medium devices */
  @media screen and (max-width: 768px) {
    .show-scroll {
        bottom: 6rem;
    }

    .scrollup__icon {
        font-size: 1.5rem;
    }
  }
  
  /* for small devices */
  @media screen and (max-width: 350px) {
    .scrollup {
        right: 1rem;
    }
  }